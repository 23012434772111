import React, { useEffect, useState } from 'react';
import { BiBot } from 'react-icons/bi';
import { BsFillMenuButtonWideFill } from 'react-icons/bs';
import { FaRegMessage, FaUsers } from 'react-icons/fa6';
import { IoStatsChartOutline } from 'react-icons/io5';
import { LuUsers } from 'react-icons/lu';
import { MdGroups, MdOutlinePhoneIphone } from 'react-icons/md';
import { NavLink, useLocation } from 'react-router-dom';
import imgLogoMeli from '../../../assets/imagenes/Mercado Libre.png';
import { SidebarTitle } from './SidebarTitle';
import { RiCustomerServiceFill } from 'react-icons/ri';

interface ListItem {
    path: string;
    label: string;
    subitems?: ListItem[];
    highlight?: React.ReactNode;
    sections?: string[];
}

type NavItem = ListItem & {
    requiredRoles: string[];
    icon: React.ReactNode;
};
type UserRole = '1' | '2' | '3';
const userSubItems: ListItem[] = [
    // { path: '/usuarios/crear', label: 'Crear usuario' },
];

const HighlightMercadolibre = () => {
    return <span className="text-white bg-[#0BDA51] py-1 px-2 rounded-lg text-xs absolute right-1 top-1.5">BETA</span>;
};

const HighlightMercadolibre2 = () => {
    return <img src={imgLogoMeli} className="group-hover:!text-primary w-5 h-4 rounded-lg text-xs absolute right-0 top-3" />;
};

export const AccesoRapido = () => {
    const [navItems, setNavItems] = useState<NavItem[]>([]);
    const [firstMenuPhoneAlias, setFirstMenuPhoneAlias] = useState<string | null>(localStorage.getItem('FirstMenuPhoneAlias'));

    useEffect(() => {

        const updateNavItems = () => {
            setNavItems([
                { path: '/meli/config', sections: ['/meli'], label: 'Mis Cuentas', icon: false, requiredRoles: ['1', '2'], highlight: <HighlightMercadolibre2 /> },
                { path: '/meli/', sections: ['/meli'], label: 'Mis Números', icon: false, requiredRoles: ['1', '2'] },
                {
                    path: `/chatbot/flow?phone=${firstMenuPhoneAlias}`,
                    sections: ['/chatbot'],
                    label: 'Editá tu chatbot',
                    icon: <BsFillMenuButtonWideFill className="group-hover:!text-primary w-7 h-6" />,
                    requiredRoles: ['1', '2'],
                },
                { path: '/chatbot/teléfonos', sections: ['/chatbot'], label: 'Teléfonos', icon: <MdOutlinePhoneIphone className="group-hover:!text-primary w-7 h-6" />, requiredRoles: ['1', '2'] },
                {
                    path: '/multiagente/usuarios',
                    sections: ['/multiagente'],
                    label: 'Usuarios',
                    icon: <LuUsers className="group-hover:!text-primary w-7 h-6" />,
                    requiredRoles: ['1', '2'],
                    subitems: userSubItems,
                },
                {
                    path: '/crm/usuarios',
                    sections: [ '/crm'],
                    label: 'Usuarios',
                    icon: <LuUsers className="group-hover:!text-primary w-7 h-6" />,
                    requiredRoles: ['1', '2']
                },
                { path: '/crm/config',  sections: ['/crm'], label: 'CRM', icon: <IoStatsChartOutline className="group-hover:!text-primary w-9 h-6" />, requiredRoles: ['1', '2'] },
                { path: '/clients', sections: ['/clients'], label: 'Clientes', icon: <FaUsers className="group-hover:!text-primary w-7 h-6" />, requiredRoles: ['1'] },
                { path: '/multiagente/areas', sections: ['/multiagente'], label: 'Areas', icon: <MdGroups className="group-hover:!text-primary w-7 h-6" />, requiredRoles: ['1', '2'] },
                { path: '/meli/answers', sections: ['/meli'], label: 'Mis Respuestas', icon: false, requiredRoles: ['1', '2'] },
            ]);
        };

        updateNavItems();
    }, [firstMenuPhoneAlias]);

    useEffect(() => {
        const handleStorageChange = () => {
            const newAlias = localStorage.getItem('FirstMenuPhoneAlias');
            setFirstMenuPhoneAlias(newAlias);
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <ul className="relative font-semibold space-y-0.5 p-4 py-0">
            <SidebarTitle text="Configuración" />
            <ul>
                {navItems.map((item) => (
                    <NavItem item={item} key={item.path} />
                ))}
            </ul>
        </ul>
    );
};

const NavItem = ({ item }: { item: NavItem }) => {
    const userRole = localStorage.getItem('user_rol_id') as UserRole | null;
    const location = useLocation();

    if (!userRole || !item.requiredRoles.includes(userRole)) {
        return null;
    }
    if (location.pathname === '/') {
        return null;
    }
    if (!item.sections?.some((i) => location.pathname.includes(i))) {
        return null;
    }
    return <Item item={item} />;
};

const Item = ({ item }: { item: NavItem }) => {
    return (
        <li className="nav-item relative">
            <NavLink to={item.path} className="group">
                <div className="flex items-center">
                    {item.icon}
                    <span className={`${item.icon ? 'ltr:pl-3 rtl:pr-3' : ''} text-[17px] font-bold text-gray-600`}>
                        {item.label} {item.highlight}
                    </span>
                </div>
            </NavLink>
            {item.subitems && (
                <ul className="ml-8 mb-2">
                    {item.subitems.map((subitem: ListItem) => (
                        <SubItem item={subitem} key={subitem.path} />
                    ))}
                </ul>
            )}
        </li>
    );
};

const SubItem = ({ item }: { item: ListItem }) => {
    return (
        <li className="nav-item secondary-navigation">
            <NavLink to={item.path} className="group">
                <div className="flex items-center">
                    <span className="ltr:pl-3 rtl:pr-3 text-[17px] font-bold text-gray-600">{item.label}</span>
                </div>
            </NavLink>
        </li>
    );
};
