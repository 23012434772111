import { useLocation } from 'react-router-dom';

/**
 * Checks if the current URL is part of the new layout.
 * @returns {boolean} True if the current URL contains one of the defined keywords of the new layout.
 */

const useIsNewLayout = () => {
    const location = useLocation();
    const keywords = ['flow','/chatbot']; // Add new keywords if necessary
    return keywords.some((keyword) => location.pathname.includes(keyword) || location.pathname === '/');
};

export default useIsNewLayout;
