
import { LogoutButton } from './LogoutButton'

export const SidebarFooter = () => {
    function rolName(){
        const rol_id = localStorage.getItem('user_rol_id');
        switch(rol_id){
            case "1":
                return "superadmin"
            case "2":
                return "admin"
            default:
                return "client"
        }
    }
    return (
        <div className="flex flex-row justify-evenly items-start w-full  h-80 dark:bg-gray-800 border bg-gray-100 py-1 pl-4 dark:border-gray-700">

            <div className="flex flex-col pb-10 truncate w-40">
                {' '}

                {/* USER NAME */}
                <span className="text-md font-semibold truncate">{localStorage.getItem('user_external_client')}</span>

                {/* USER ROL */}
                <span className="text-xs ">{rolName()}</span>
            </div>
            <div className="flex items-center">
                {' '}
                {/* <ThemeConfigButton /> */}
                < LogoutButton size="md" />
            </div>
        </div>
    )
}
