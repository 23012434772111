
export const SidebarTitle = ({ text }) => {
    return (
        <h2 className="py-2 px-7 flex items-center font-extrabold bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08] -mx-4 mb-1">
            <svg className="w-4 h-5 flex-none hidden" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            <span className="font-bold text-gray-600 uppercase">{text}</span>
        </h2>
    )
}
