// src/main.tsx
import * as Sentry from "@sentry/react";
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './tailwind.css';
import "dayjs/locale/es";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes, convertRoutes } from './router';
import { Provider } from 'react-redux';
import store from './store/index';
import { AuthProvider } from './context/authContext';
import {
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { initializeGoogleAnalytics } from './googleAnalytics'; // Importa la función de inicialización
declare global {
    interface Window {
        dataLayer: any[]; // Define el tipo de dataLayer según tus necesidades
        FB: any;
        launchWhatsAppSignup: (token) => void;
    }
}
const GoogleAnalytics: React.FC = () => {
    useEffect(() => {
        initializeGoogleAnalytics();
        return () => {
            // Aquí podrías realizar tareas de limpieza si fuera necesario al desmontar el componente
        };
    }, []);

    return null; // No necesitamos renderizar nada en el DOM para Google Analytics
};
Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_NODE_ENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ),
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ),
    replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ),
});

const routeObjects = convertRoutes(routes);
const router = createBrowserRouter(routeObjects);
const queryClient = new QueryClient()
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <>
        <link rel="stylesheet" href="https://use.typekit.net/xmc4puz.css" />
        <Provider store={store}>
            <GoogleAnalytics />
            <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                </QueryClientProvider>
            </AuthProvider>
        </Provider>
    </>
);

console.log(`
       d8888  .d88888b.  888    d8P  8888888
       d88888 d88P" "Y88b 888   d8P     888
      d88P888 888     888 888  d8P      888
     d88P 888 888     888 888d88K       888
    d88P  888 888     888 8888888b      888
   d88P   888 888     888 888  Y88b     888
  d8888888888 Y88b. .d88P 888   Y88b    888
 d88P     888  "Y88888P"  888    Y88b 8888888
`);
console.log('Desarrollamos Inteligencia Artificial para automatizar las tareas de tu empresa!');
