// src/utils/googleAnalytics.ts

declare global {
    interface Window {
        dataLayer: any[];
        gtag?: (event: string, action: string | Date, data?: object) => void;
    }
}

export const initializeGoogleAnalytics = (): void => {
    if (typeof window.gtag !== 'function') {
        const analyticsId = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;
        window.dataLayer = window.dataLayer || [];
        function gtag(event: string, action: string | Date, data?: object) {
            window.dataLayer.push({ event, action, ...data });
        }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', analyticsId);
    }
};

export const gtag = (event: string, action: string, data?: object) => {
    if (typeof window.gtag === 'function') {
        window.gtag(event, action, data);
    } else {
        console.error('Google Analytics is not initialized');
    }
};
