import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { finishTutorial, nextPage, prevPage, setPage, setPosition, setTutorial } from '../../store/StepperSlice';
import { useNavigate } from 'react-router-dom';


export const useStepper = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { tutorial, positionStep, pageStep, stepsTutorial } = useSelector((state) => state.stepper);
    const showMessage = (msg = '', type = 'success') => {
        const toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            customClass: { container: 'toast' },
        });
        toast.fire({
            icon: type,
            title: msg,
            padding: '10px 20px',
        });
    };

    const nextStep = (stepTutorial) => {
        if (stepTutorial.nextPage != '') {
            dispatch(nextPage(stepTutorial))
            navigate(stepTutorial.nextPage)
        } else if (stepTutorial.nextPage == '') {
            dispatch(finishTutorial())
            showMessage('Completaste el tutorial', '')
            navigate('/', { replace: true })
        }
    }

    const prevStep = (stepTutorial) => {
        dispatch(prevPage(stepTutorial))
        navigate(stepTutorial.prevPage)
    }

    const skipTutorial = () => {
        dispatch(setTutorial(false))
        showMessage('Saliste del tutorial', '')
        navigate('/')
    }
    const logOut = () => {
        localStorage.setItem('auth', 'false');
        localStorage.clear()
        navigate('/auth')
    }
    const handleStepComplete = () => {
        if (tutorial) {
            showMessage('Paso completado con exito', 'success')
            nextStep(stepsTutorial[positionStep])
        }

    }

    return { tutorial, positionStep, pageStep, nextStep, prevStep, skipTutorial, logOut, stepsTutorial, handleStepComplete }
}
