import Tippy from '@tippyjs/react';
import { useEffect, useMemo, useState } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { IoMdArrowBack } from 'react-icons/io';
import { MdOutlineLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoAokiSoftBrown from '../../assets/imagenes/logo-aoki-soft-brown.svg';
import { ModalMiPrimerMenu } from '../../pages/Flow/ModalMiPrimerMenu';
import { IRootState } from '../../store';
import { closeSidebar } from '../../store/themeConfigSlice';
import { CustomizedToolTip } from '../CustomizedToolTip';
import { LogoutButton } from './Sidebar/SidebarFooter/LogoutButton';

const Header = () => {
    const location = useLocation();

    useEffect(() => {
        const selector = document.querySelector('ul.horizontal-menu a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const all: any = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
            for (const element of all) {
                all[0]?.classList.remove('active');
            }
            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele?.classList.add('active');
                    });
                }
            }
        }
    }, [location]);

    const isNewHeader = location.pathname.includes('flow') || location.pathname.includes('chatbot/');

    useEffect(() => {
        if (isNewHeader) {
            dispatch(closeSidebar());
        }
    }, []);

    const navigate = useNavigate();
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const dispatch = useDispatch();
    const isInFlow = location.pathname.includes('flow');
    const handleLogOut = () => {
        navigate('/auth/login');
        if (!isInFlow) {
            localStorage.setItem('auth', 'false');
            localStorage.clear();
        }
    };

    const [openTutorial, setOpenTutorial] = useState(false);

    const isHome = location.pathname === '/';

    return (
        <header className={themeConfig.semidark && themeConfig.menu === 'horizontal xl:lg:md:hidden' ? 'dark xl:lg:md:hidden' : ''}>
            <div className="shadow-sm">
                <div
                    className={`${
                        isHome || isNewHeader ? 'absolute left-8 right-8 rounded-3xl items-center h-[4.5rem] ' : 'items-center relative w-full py-8 h-15'
                    } bg-[#537288]/70 backdrop-blur-lg flex px-10 my-5  dark:bg-black/60`}
                >
                    {' '}
                    {(isHome || isNewHeader) && <img src={logoAokiSoftBrown} className={`h-9`} alt={'aokiLogo'} />}
                    {isNewHeader && <h1 className="md:block hidden font-sans text-2xl font-bold text-[#E6E0D0] mx-4 transform translate-y-[10px] ">Editá tu chatbot</h1>}
                    {!isHome && (
                        <div className="dark:text-[#d0d2d6] flex space-x-4 w-32">
                            {/* Botón para ir al inicio */}
                            {!isNewHeader && (
                                <Tippy content={<span className="dark:bg-gray-900 bg-gray-200 px-2 dark:text-white rounded-md text-xs py-1">Ir al inicio</span>}>
                                    <div>
                                        <Link
                                            to="/"
                                            className={`bg-white-light/40 p-2 flex items-center  rounded-full  dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60`}
                                        >
                                            <AiOutlineHome className={` ${isNewHeader ? 'text-amber-100 w-7 h-7' : 'w-4.5 h-4.5'} `} />
                                        </Link>
                                    </div>
                                </Tippy>
                            )}
                            {/* Botón para volver a la página anterior */}

                            {location.pathname != '/chatbot/chatbot-onboarding' && !isNewHeader && (
                                <Tippy content={<span className="dark:bg-gray-900 bg-gray-200 px-2 dark:text-white rounded-md text-xs py-1">Volver atrás</span>}>
                                    <div>
                                        <button
                                            onClick={() => navigate(-1)}
                                            className="flex items-center p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                                        >
                                            <IoMdArrowBack className="w-4.5 h-4.5" />
                                        </button>
                                    </div>
                                </Tippy>
                            )}
                        </div>
                    )}
                    <div className="sm:flex-1 ltr:sm:ml-0 ltr:ml-auto sm:rtl:mr-0 rtl:mr-auto flex items-center space-x-1.5 lg:space-x-2 rtl:space-x-reverse dark:text-[#d0d2d6]">
                        {/* <ThemeConfigButton /> */}
                    </div>{' '}
                    <div className="horizontal-logo flex justify-between items-center ">
                        {!isHome && !isNewHeader && (
                            <div className="md:hidden block">
                                <LogoutButton size="sm" />
                            </div>
                        )}
                    </div>
                    {/* isNewHeader ? 'border-2 border-amber-100 p-1' :  */}
                    {isNewHeader && location.pathname !== '/' && (
                        <div className="flex gap-4 ">
                            <div className="md:w-40 md:flex md:flex-col  md:center  ">
                                <h1
                                    className=" md:w-[8rem] text-2xl text-[#E6E0D0] mx-4 md:block hidden transform translate-y-[9px] cursor-pointer "
                                    onClick={() => {
                                        setOpenTutorial(true);
                                    }}
                                >
                                    Ver tutorial
                                </h1>
                                <hr className="transform border-[.10px] scale-50 translate-y-1.5 " />
                            </div>
                            <CustomizedToolTip text="Ir a inicio" id="inicio">
                                <div className="border-2 border-amber-100 rounded-full">
                                    <Link to="/" className={`p-2 flex items-center rounded-full hover:text-primary hover:bg-white-light/90`}>
                                        <AiOutlineHome className={`text-amber-100 w-5 h-5`} />
                                    </Link>
                                </div>
                            </CustomizedToolTip>
                        </div>
                    )}
                    <div className="block  mx-4">
                        <CustomizedToolTip text="Cerrar sesión" id="cerrar">
                            <div className="border-2 border-amber-100 rounded-full ">
                                <Link to="/auth/login" className="text-amber-100 p-2 flex items-center rounded-full hover:text-primary hover:bg-white-light/90" onClick={handleLogOut}>
                                    <MdOutlineLogout className={`text-amber-100 w-5 h-5`} />
                                </Link>{' '}
                            </div>
                        </CustomizedToolTip>
                    </div>
                </div>
            </div>
            <ModalMiPrimerMenu isOpen={openTutorial} setIsOpen={setOpenTutorial} />
        </header>
    );
};

export default Header;
