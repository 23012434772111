import { createSlice } from '@reduxjs/toolkit';

const contextIASlice = createSlice({
    name: 'IASlice',
    initialState: {
        tagsIA: [],
        tagsIANames: []
    },
    reducers: {
        setTagsIA(state, action) {
            state.tagsIA = action.payload;

        },
        setTagsIANames(state, action) {
            state.tagsIANames = action.payload;

        },


    },
});

export const { setTagsIA, setTagsIANames } = contextIASlice.actions;

export default contextIASlice.reducer;
