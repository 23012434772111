import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import imgLogoAoki from '../../../assets/imagenes/logo-aoki.svg';
import { useConfigGeneral } from '../../../hooks/ConfigGeneral/useConfigGeneral.js';
import { getKey } from '../../../apis/crm';
import { SidebarFooter } from './SidebarFooter/SidebarFooter';
import { AccesoRapido } from './AccesoRapido';
import { IoChevronBack } from 'react-icons/io5';
import useIsNewLayout from '../../../hooks/Utils/useNewLayout';
import { toggleSidebar } from '../../../store/themeConfigSlice';

const Sidebar = () => {
    const { themeConfig } = useConfigGeneral();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNewLayout = useIsNewLayout();
    useEffect(() => {
        const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link') || [];
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
    }, [location]);

    useEffect(() => {
        getKey()
            .then((res: { data: { ok: number; data: any } }) => {
                if (res.data.ok === 1) {
                    let key = res.data.data;
                    sessionStorage.setItem('crmk', key);
                }
                if (res.data.ok === 0) {
                    sessionStorage.removeItem('crmk');
                }
            })
            .catch(() => {
                sessionStorage.removeItem('crmk');
            });
    }, []);
    return (
        <div>
            <nav className={`${!isNewLayout ? 'fixed' : 'hidden'} sidebar  min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300`}>
                <div className="bg-white dark:bg-black h-full">
                    <div className="flex justify-center flex-row items-center px-4 py-4">
                        <Link to="/">
                            <div className="flex flex-col justify-center items-center w-full">
                                <img src={imgLogoAoki} className="h-10 " />
                            </div>
                        </Link>
                    </div>
                    <PerfectScrollbar className="h-[calc(100vh-120px)] relative">
                        {/* <Actions /> */}
                        <div className="flex items-center my-4 cursor-pointer md:hidden" onClick={() => navigate('/')}>
                            <span className="text-lg ml-3 text-blue-500">
                                {' '}
                                <IoChevronBack />
                            </span>

                            <span className="ltr:pl-3 rtl:pr-3 text-[17px] font-bold text-gray-600">Volver</span>
                        </div>
                        <AccesoRapido />
                    </PerfectScrollbar>

                    <SidebarFooter />
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;
