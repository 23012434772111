import { combineReducers, configureStore } from '@reduxjs/toolkit';
import themeConfigSlice from './themeConfigSlice';
import authReducer from './AuthSlice';
import styleSliceReducer from './StyleSlice';
import contextIASliceReducer from './ContextIASlice';
import stepperSliceReducer from './StepperSlice';

const rootReducer = combineReducers({
    themeConfig: themeConfigSlice,
    auth: authReducer,
    style: styleSliceReducer,
    constextIA: contextIASliceReducer,
    stepper: stepperSliceReducer, 
});

export default configureStore({
    reducer: rootReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
