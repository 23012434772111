import { Modal } from '../../components/Modal';

export const ModalMiPrimerMenu = ({ isOpen, setIsOpen }) => {
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} showX={false} isRounded={true}>
            <div>
            <h1 className='font-sans font-bold text-[#003460] text-xl mb-1' >Cómo armar mi primer Chatbot</h1>
            <hr className='mb-4 border-[#0881a3] border-2 w-[30rem] transform -translate-x-44'/>
            <iframe
            className='rounded-3xl '
            width="100%"
            style={{ minHeight: '20rem' }}
            src="https://www.youtube.com/embed/e1u5K1ZJxqA?si=4IyK_2n575EV1yxQ"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            ></iframe>
            <div className="flex flex-row justify-end gap-4 mt-4">
                <button className="bg-[#f58634] text-slate-50  font-semibold  font-sans px-9 rounded-lg py-1" onClick={handleClose}>
                    Cerrar
                </button>
            </div>
            </div>
        </Modal>
    );
};
