import { client } from '../client';
import { clientCromo } from '../clientCromo';

export const changePhone = async (phone_id_alias) => {
    let res: { ok: number; data: object; error: null | string } = { ok: 0, data: [], error: null };
    try {
        const response = await clientCromo.put(
            `/api/auth/company-config`,
            {
                phone_id_alias: phone_id_alias,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('andoToken')}`,
                },
            }
        );

        if (response.status == 200) {
            res.data = response.data;
            res.ok = 1;
        } else {
            res.error = 'Error';
        }
    } catch (error: any) {
        res.error = error.response.data.data;
        console.log('Post product', error);
    }
    return res;
};

export const getPhones = async () => {
    let res: { ok: number; data: object; error: null | string } = { ok: 0, data: [], error: null };
    try {
        const response = await client.get(`/api/v2/whatsapp/phone/by_owner`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('andoToken')}`,
            },
        });
        if (response.status == 200) {
            res.data = response.data.data;
            res.ok = 1;
        } else {
            res.error = 'Error ';
        }
    } catch (error: any) {
        res.error = error.response.data.data;
        console.log('Post product', error);
    }
    return res;
};

export const getConfig = async () => {
    let res: { ok: number; data: object; error: null | string } = { ok: 0, data: [], error: null };
    try {
        const response = await clientCromo.get(`/api/auth/company-config`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('andoToken')}`,
            },
        });
        if (response.status == 200) {
            res.data = response.data.data;
            res.ok = 1;
        } else {
            res.error = 'Error ';
        }
    } catch (error: any) {
        res.error = error.response.data.data;
        console.log('Post product', error);
    }
    return res;
};
export const getKey = async () => {
    try {
        const response = await client.get(`/api/admin-panel/crmk`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('andoToken')}`,
            },
        });
        return response;
    } catch (err) {
        return err;
    }
};

export const createClient = async (client) => {
    clientCromo.post(
        '/api/external/client',
        {
            clients: [client],
        },
        {
            headers: {
                'Content-Type': 'application/json',
                aokikey: `25de08f518983746fe05bad8f5bb1d84`,
            },
        }
    );
};
