import { createSlice } from '@reduxjs/toolkit';

// Función para parsear el valor almacenado en localStorage
function parseBooleanFromString(value: string): boolean {
  const lowercasedValue = value.toLowerCase();
  return lowercasedValue === "true" || lowercasedValue === "1" || lowercasedValue === "yes";
}

const localStorageAuth = localStorage.getItem("auth");
const isAuthenticatedFromLocalStorage = localStorageAuth ? parseBooleanFromString(localStorageAuth) : false;

const initialState = {
  user: null,
  isAuthenticated: isAuthenticatedFromLocalStorage, 
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },
    logout(state) {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
