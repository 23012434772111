import Tippy from '@tippyjs/react';
import { MdOutlineLogout } from 'react-icons/md';
import logOutIcon from '../../../../assets/imagenes/logout-header.svg';
import { Link } from 'react-router-dom';
import { useConfigGeneral } from '../../../../hooks/ConfigGeneral/useConfigGeneral.js';
import useIsNewLayout from '../../../../hooks/Utils/useNewLayout.js';

export const LogoutButton = ({ size = 'md' }) => {
    const { handleLogOut } = useConfigGeneral();
    const isNewLayout = useIsNewLayout();
    return (
        <Tippy content={<span className="dark:bg-gray-900 bg-gray-200 px-2 dark:text-white rounded-md text-xs py-1 ">Cerrar sesion</span>}>
            <div>
                <Link
                    to="/auth/login"
                    className={
                        isNewLayout ? 'text-amber-100 ' : `mx-2 flex items-center p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60 `
                    }
                    onClick={handleLogOut}
                >
                    {isNewLayout ? <img src={logOutIcon} alt="Log Out" className=' h-10 mx-8'/> : <MdOutlineLogout className={`${size === 'sm' && 'w-4.5 h-4.5'} ${size === 'md' && 'w-7 h-6'}`} />}
                </Link>{' '}
            </div>
        </Tippy>
    );
};
