import { createSlice } from '@reduxjs/toolkit';

const stepperSlice = createSlice({
    name: 'stepper',
    initialState: {
        tutorial: false,
        positionStep: 0,
        pageStep: "/",
        stepActually: {},
        stepsTutorial: []
    },
    reducers: {
        setTutorial(state, action) {
            localStorage.setItem('tutorial', action.payload)
            state.tutorial = action.payload;

        },
        setPosition(state, action) {
            state.positionStep = action.payload;

        },
        setPage(state, action) {
            state.pageStep = action.payload;

        },
        setStepsTutorial(state, action) {
            state.stepsTutorial = action.payload
        },
        nextPage(state, action) {
            state.stepActually = { ...action.payload }
            state.pageStep = action.payload.nextPage
            state.positionStep = action.payload.position + 1

        },
        prevPage(state, action) {
            state.stepActually = { ...action.payload }
            state.pageStep = action.payload.nextPage
            state.positionStep = action.payload.position - 1
        },
        finishTutorial(state, action) {
            localStorage.setItem('tutorial', false)
            state.tutorial = false
            state.positionStep = 0
            state.pageStep = "/"
            state.stepsTutorial = []
        },
        setStepActually(state, action) {
            state.stepActually = { ...action.payload }
        }
    }
});

export const { setPage, setPosition, setTutorial, setStepsTutorial, nextPage, prevPage, finishTutorial, setStepActually } = stepperSlice.actions;

export default stepperSlice.reducer;
