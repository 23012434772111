import { FC, ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';

interface CustomizedToolTipInterface {
    noArrow?: boolean;
    text: string;
    children: ReactNode;
    place?: 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end';
    id: string;
    anchorContainerStyle?: string;
}

export const CustomizedToolTip: FC<CustomizedToolTipInterface> = ({ children, place = 'bottom', text, noArrow, id, anchorContainerStyle }) => {
    return (
        <div className='w-full flex justify-center'>
            <div className={ `${id} ${anchorContainerStyle}`} id={id}>{children}</div>
            <Tooltip
                data-tooltip-id={id}
                border="1px solid #003460"
                style={{ backgroundColor: '#e6e0d0', color: '#003460', borderRadius: '10px',zIndex:'10' }}
                noArrow={noArrow}
                anchorSelect={`#${id}`}
                place={place}>
                {text}
            </Tooltip>
        </div>
    );
};
