import { useDispatch, useSelector } from 'react-redux'
import { setfontSizeTexts, setrange, setPanelStyle, } from '../../store/StyleSlice'
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

export const useConfigGeneral = () => {
    const dispatch = useDispatch()
    const themeConfig = useSelector((state) => state.themeConfig);
    const { fontSizeTexts, panelStyle, range, showIAs } = useSelector((state) => state.style);

    const handleChangeFontSizeText = (value) => {
        switch (value) {
            case '1':
                dispatch(setfontSizeTexts('xs'))
                dispatch(setrange('1'))
                break;
            case '2':
                dispatch(setfontSizeTexts('md'))
                dispatch(setrange('2'))
                break;
            case '3':
                dispatch(setfontSizeTexts('lg'))
                dispatch(setrange('3'))
                break;
            case '4':
                dispatch(setfontSizeTexts('xl'))
                dispatch(setrange('4'))
                break;
            case '5':
                dispatch(setfontSizeTexts('2xl'))
                dispatch(setrange('5'))
                break;
            default:
                dispatch(setfontSizeTexts('lg'))
                dispatch(setrange('3'))
                break;
        }
    }
    const togglePanelStyle = () => {
        dispatch(setPanelStyle(!panelStyle))
    }

    const handleLogOut = async () => {
        localStorage.setItem('auth', 'false');
        localStorage.clear()
        await handleGoogleSignOut();
    };

    const handleGoogleSignOut = async () => {
        try {
            await signOut(auth);
          
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleChangeFontSize = (font) => {
        dispatch(setfontSizeTexts(font))

    }

    return {
        handleChangeFontSizeText, handleChangeFontSize, fontSizeTexts, range, themeConfig, handleLogOut, togglePanelStyle, panelStyle,
    }
}
