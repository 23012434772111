import { createSlice } from '@reduxjs/toolkit';

const styleSlice = createSlice({
    name: 'style',
    initialState: {
        fontSizeTexts: "lg",
        range: "3",
        panelStyle: false,
        showIAs: false,
        isMobile: false
    },
    reducers: {
        setfontSizeTexts(state, action) {
            state.fontSizeTexts = action.payload;

        },
        setrange(state, action) {
            state.range = action.payload;

        },
        setPanelStyle(state, action) {
            state.panelStyle = action.payload;

        },
        setShowIAs(state, action) {
            state.showIAs = action.payload;

        },
        setIsMobile(state, action) {
            state.isMobile = action.payload

        }
    },
});

export const { setfontSizeTexts, setrange, setPanelStyle, setShowIAs, setIsMobile } = styleSlice.actions;

export default styleSlice.reducer;
