import axios from 'axios';
import { apiUrl } from '../../config';

const axiosWithTokenCheck = () => {
    const instance = axios.create({
        baseURL: apiUrl
    });

    instance.interceptors.request.use(
        (config) => {

            const token = localStorage.getItem('andoToken');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const token = localStorage.getItem('andoToken');
            if (error.response && token) {
                if (error.response.status === 401) {
                    return window.location.href = '/auth/login';

                }
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

export const client = axiosWithTokenCheck();
